import store from "../../state-management";
import { initialState as errorInitialState, setError } from "../../state-management/errors/errors.reducer";

class ErrorMessageHandler {
  private static UNAUTORIZED_HTTP_STATUS = 401;
  private static TIME_INTERVAL_TO_REMOVE_ERROR_MESSAGE = 15000;
  private timeout: any = null;
  private static _instance: ErrorMessageHandler;

  private constructor() {}

  public static getInstance() {
    return this._instance || (this._instance = new this());
  }
  dispatchError(showError: boolean, status: number) {
    // dispatch error message
    const isJWTError = status === ErrorMessageHandler.UNAUTORIZED_HTTP_STATUS;
    store.dispatch(
      setError({
        jwtCheck: isJWTError,
        isError: showError,
        status: status,
      }),
    );
    if (showError && !isJWTError) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        store.dispatch(setError(errorInitialState));
      }, ErrorMessageHandler.TIME_INTERVAL_TO_REMOVE_ERROR_MESSAGE);
    }
  }
}
export default ErrorMessageHandler.getInstance();
