import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { setupCache } from "axios-cache-adapter";
import { config } from "../config";
import store from "../state-management";
import { setLoading } from "../state-management/loading/loading.reducer";
import { Token as TokenService } from "./Token";
import Qs from "qs";
import errorMessageHandler from "./utils/errorMessageHandler";

export class Request {
  public static cancel: any;
  private base: string;
  private cache: any;

  constructor() {
    this.base = config.apiUrl as string;
    this.cache = setupCache({
      maxAge: 15 * 60 * 1000,
    });
  }

  public async get<T = any>(
    endpoint: string,
    cached = true,
    showLoading = true,
    params: Partial<AxiosRequestConfig> = {},
  ): Promise<AxiosResponse<T>> {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.sendRequest(endpoint));
    } else {
      return this.sendRequest<T>(endpoint, cached, showLoading, params);
    }
  }

  public async post(endpoint: string, body: any, otherParams: Partial<AxiosRequestConfig> = {}) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.postRequest(endpoint, body));
    } else {
      return this.postRequest(endpoint, body, otherParams);
    }
  }

  public async patch(endpoint: string, body: any) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.patchRequest(endpoint, body));
    } else {
      return this.patchRequest(endpoint, body);
    }
  }
  public async put(endpoint: string, body: any, cancelOption = false) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.putRequest(endpoint, body));
    } else {
      return this.putRequest(endpoint, body);
    }
  }
  public async delete(endpoint: string) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.deleteRequest(endpoint));
    } else {
      return this.deleteRequest(endpoint);
    }
  }

  public async head(endpoint: string) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.deleteRequest(endpoint));
    } else {
      return this.headRequest(endpoint);
    }
  }
  public async getBlob(endpoint: string, cached = true, params = {}) {
    const httpInstance = this.getHttpInstance(cached);
    const param = { ...params, responseType: "blob" as "blob" };
    return httpInstance.get(endpoint, param);
  }

  public async refreshToken() {
    const body = JSON.stringify({
      refresh_token: localStorage.getItem("refreshToken"),
    });
    const endpoint = `/token/refresh`;
    const httpInstance = this.getHttpInstance();
    return httpInstance.post(endpoint, body);
  }

  public async checkJWT() {
    const endpoint = `/api/token-check`;
    const httpInstance = this.getHttpInstance(false, false);
    return httpInstance.get(endpoint);
  }
  private sendRequest<T = any>(endpoint: string, cached = true, showLoading = true, overrideParams = {}) {
    let param = {};

    param = {
      ...param,
      ...overrideParams,
      paramsSerializer: (params: any) => {
        return Qs.stringify(params);
      },
    };

    const httpInstance = this.getHttpInstance(cached, showLoading);

    return httpInstance.get<T>(endpoint, param);
  }

  private postRequest(endpoint: string, body: any, otherParams: Partial<AxiosRequestConfig> = {}) {
    let param = {};
    param = {
      ...param,
      ...otherParams,
    };

    const httpInstance = this.getHttpInstance();
    return httpInstance.post(endpoint, body, param);
  }
  private patchRequest(endpoint: string, body: any) {
    let param = {};
    const httpInstance = this.getHttpInstance();
    return httpInstance.patch(endpoint, body, param);
  }
  private putRequest(endpoint: string, body: any) {
    let param = {};
    const httpInstance = this.getHttpInstance();
    return httpInstance.put(endpoint, body, param);
  }

  private deleteRequest(endpoint: string) {
    let param = {};
    const httpInstance = this.getHttpInstance();
    return httpInstance.delete(endpoint, param);
  }

  private headRequest(endpoint: string) {
    let param = {};
    const httpInstance = this.getHttpInstance(false, false, false);
    return httpInstance.head(endpoint, param);
  }
  private getHttpInstance(cached?: boolean, showLoading = true, showErrorMessage = true) {
    const { base } = this;
    let adapter = this.cache.adapter;
    if ("undefined" !== typeof cached && !cached) {
      adapter = undefined;
    }
    if (showLoading && !store.getState().loadingReducer.isLoading) {
      // show loading
      store.dispatch(
        setLoading({
          isLoading: true,
        }),
      );
    }
    const httpInstance = axios.create({
      adapter,
      baseURL: `${base}`,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authorization: `Bearer ${localStorage.getItem("tokenECA")}`,
      },
    });

    httpInstance.interceptors.response.use(
      async (response) => {
        // show loading
        if (store.getState().loadingReducer.isLoading) {
          store.dispatch(
            setLoading({
              isLoading: false,
            }),
          );
        }
        return response;
      },
      (error) => {
        if (store.getState().loadingReducer.isLoading) {
          store.dispatch(
            setLoading({
              isLoading: false,
            }),
          );
        }
        if (error.message && error.message === "canceled") {
          return Promise.reject(error);
        }
        const httpStatus = error.response ? error.response.status : 0;
        errorMessageHandler.dispatchError(showErrorMessage, httpStatus);

        return Promise.reject(error);
      },
    );
    return httpInstance;
  }
}

export default new Request();
