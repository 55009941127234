import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import { RouteItem } from "../navigation/navigation-item/types/NavigationItem";
import "./Content.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../state-management";
import { SzAlert } from "@suezenv/react-theme-components";
import ErrorAuthenticationMessage from "../messages/ErrorAuthenticationMessage";
import { useTranslation } from "react-i18next";

interface Props {
  routes: RouteItem[];
}

const Content: React.FC<Props> = ({ routes }) => {
  const errorState = useSelector((state: RootState) => state.errorReducer);
  const userState = useSelector((state: RootState) => state.userReducer);
  const { t } = useTranslation();
  return (
    <main className={"main-content"}>
      {errorState.isError && !errorState.jwtCheck && (
        <SzAlert className="mb-0" variant="danger" transparent>
          {t("error.communicationError")}
        </SzAlert>
      )}
      {errorState.isError && errorState.jwtCheck && <ErrorAuthenticationMessage show={true} />}
      {userState.isLogged && userState.data && (
        <Switch>
          {routes.map(
            ({ route, page, role }) =>
              (!role || userState.data!.roles.includes(role)) && (
                <Route exact key={route} path={route} component={page as React.ComponentType} />
              ),
          )}
          <Route path="/">
            <Redirect to="/enquete" />
          </Route>
        </Switch>
      )}
    </main>
  );
};

export default Content;
