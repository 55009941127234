import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "./errors.type";

export const initialState: ErrorType = {
  jwtCheck: false,
  isError: false,
  status: -1,
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorType>) => {
      state.isError = action.payload.isError;
      state.status = action.payload.status;
      state.jwtCheck = action.payload.jwtCheck !== null ? action.payload.jwtCheck : state.jwtCheck;
    },
  },
});

export const { setError } = errorSlice.actions;

export default errorSlice.reducer;
