import React from "react";
import { SurveyRequest } from "../../../../services/types/survey";
import { SzCheckbox, SzIcon } from "@suezenv/react-theme-components";
import { ColumnDescription } from "react-bootstrap-table-next";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";
import { SzCheckboxProps } from "@suezenv/react-theme-components/build/components/SzCheckbox/SzCheckbox";
import { dateFormatter } from "../../survey-filter/form-filter/services/helper";
import { SortType } from "../../../../services/types/common";

export const getColumns = (
  t: TFunction,
  onSortData: (sort: SortType) => void,
  selectedRows: SurveyRequest[],
  onCheckboxChange: (isChecked: boolean, surevy: SurveyRequest) => void,
  getOption: (key: string, code?: null | string | boolean) => any,
) => {
  const columns: ColumnDescription<SurveyRequest>[] = [
    {
      dataField: "checkbox",
      isDummyField: true,
      text: "",
      formatter: (_cell, survey, _rowIndex, { selectedRows }) => {
        return (
          <SzCheckbox
            id={survey.id}
            value={survey.id}
            name=""
            label=""
            type="checkbox"
            checked={selectedRows.map((item: SurveyRequest) => item.id).includes(survey.id)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onCheckboxChange(event.target.checked, survey)}
          />
        );
      },
      formatExtraData: {
        selectedRows: [...selectedRows],
      },
      headerClasses: "sticky-header",
    },
    {
      dataField: "currentStatus.status.label",
      text: t("survey:table.status"),
      headerClasses: "sticky-header",
    },
    {
      dataField: "data.donneeAdmin.numITV",
      text: t("survey:table.surveyNumber"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "ecaTypeCode",
      text: t("survey:table.surveryType"),
      sort: true,
      formatter: (value: string) => (
        <div className={`statut ${value}`}>{getOption("associationControlVisit", value)?.label}</div>
      ),
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.donneeAdmin.dateITV",
      text: t("survey:table.visitedAt"),
      sort: true,
      formatter: (value: string) => <>{dateFormatter(value)}</>,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.donneeAdmin.datePrevue",
      text: t("survey:table.expectedAt"),
      sort: true,
      formatter: (value: string) => <>{dateFormatter(value)}</>,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.conformitePDC.conformite",
      text: t("survey:table.conforme"),
      sort: true,
      headerClasses: "sticky-header",
      formatter: (value: string) => <div>{getOption("conformitePDC", value)?.label}</div>,
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.infoPDC.adressePDC.numero",
      text: t("survey:table.address.streetNumber"),
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.infoPDC.adressePDC.nomVoie",
      text: t("survey:table.address.street"),
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.infoPDC.adressePDC.codePostal",
      text: t("survey:table.address.postalCode"),
      headerClasses: "sticky-header",
      sort: true,
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.infoPDC.adressePDC.commune",
      text: t("survey:table.address.city"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.infoPDC.idPDC",
      text: t("survey:table.pdcNumber"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.infoPDC.typePDC",
      text: t("survey:table.pdcType"),
      sort: true,
      headerClasses: "sticky-header",
      formatter: (value: string) => <div>{getOption("controlPointType", value)?.label}</div>,
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.donneeAdmin.contrat",
      text: t("controlPoint:table.referenceContrat.contrat"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "data.donneeAdmin.contratLib",
      text: t("controlPoint:table.referenceContrat.title"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "updatedAt",
      text: t("survey:table.updateAt"),
      sort: true,
      formatter: (value: string) => <>{dateFormatter(value)}</>,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "updatedBy",
      text: t("survey:table.updatedBy"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "formName",
      text: t("survey:table.typeForm"),
      sort: true,
      formatter: (value: string | null) => {
        return !value ? "par défaut" : value;
      },
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "actions",
      text: t("survey:table.actions"),
      isDummyField: true,
      formatter: (_value: any, survey) => (
        <Link to={`/enquete/view/${survey.id}`}>
          {" "}
          <SzIcon icon="copy-paste" variant="line" />
        </Link>
      ),
      classes: "actions",
      headerClasses: "sticky-header",
    },
  ];

  return columns;
};
